/*jshint evil:true*/ // eval has to be used when parsing 'data-alternatives' from data attributes since it contains single quotes
/**
 * Copyright (C) Sitevision AB 2002-2025, all rights reserved
 *
 * @author Karl Eklöf
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import {getPortletResourceUri} from '../../util/portletUtil';
import {
   ObjectUtil as objectUtil,
   i18n as _i18n,
   ErrorUtil as errorUtil,
   DialogUtil as dialogUtil,
} from '@sv/util';

const
   i18n = function(key, args) {
      return _i18n.getText('portlet.social.createdocument.createdocument', key, args);
   },
   createDocumentTemplate = _.template(
         '<div>'+
         '  <span class="help-block"><%- description %></span>'+
         '  <form class="sv-fn-event-edit form-horizontal">'+
         '    <div class="control-group">'+
         '       <label class="control-label">' +i18n('name') +'</label>'+
         '       <div class="controls">'+
         '          <input class="input-xlarge" data-name type="text" name="title" maxlength="50" autocomplete="off" value="">'+
         '       </div>'+
         '    </div>'+
         '    <div class="control-group">'+
         '      <label class="control-label">' +i18n('tags') +'</label>'+
         '      <div class="controls">'+
         '          <input class="input-xlarge" data-file-tags type="text" name="tags">'+
         '      </div>'+
         '    </div>'+
         '    <% if (locations && locations.length > 0) { %>'+
         '      <div class="control-group">'+
         '        <label class="control-label">' +i18n('location') +'</label>'+
         '        <div class="controls">'+
         '          <select class="input-xlarge" data-location name="location">'+
         '          <% _.each(locations, function(location) { %>'+
         '              <option value="<%= location.value %>" title="<%= location.title %>"><%= location.name %></option>'+
         '          <% }); %>'+
         '          </select>'+
         '        </div>'+
         '      </div>'+
         '    <% } %>'+
         '  </form>'+
         '</div>');

var split = function( val ) {
   return val.split( /,\s*/ );
};

var getStoredValue = function() {
   if ('localStorage' in window && window.localStorage !== null) {
      return window.localStorage.getItem('sv-tags');
   }
};

$('.sv-createdocument-portlet').each(function() {
   var $portlet = $(this),
      aPortletId = objectUtil.getObjectId($portlet.attr('id'));

      // create document
   $('.sv-fn-create-document').on('click', function(e) {
      var data = $(e.currentTarget).data();
      $.ajax({
         type: 'GET',
         url : getPortletResourceUri(aPortletId, 'document')
      }).done(function(locations) {
         var modalContent = $(createDocumentTemplate(_.extend(data, { locations: locations })));
         var buttons = [
            {
               text: i18n('cancel')
            },
            {
               text: i18n('create'),
               primary: true,
               callback: function() {
                  // get data from fields in template
                  var fileName = $('input[data-name]').val(),
                     tags = $('input[data-file-tags]').val(),
                     location = $('select[data-location]').val();
                  var encodedName = encodeURIComponent(fileName);

                  // do create document
                  $.ajax({
                     type: 'PUT',
                     url : getPortletResourceUri(aPortletId, 'document') + '&template=' + data.template +'&location=' +location +'&name=' + encodedName +'&tags=' +encodeURIComponent(tags),
                     success: function(data) {
                        if (data.webdavURL && data.webdavURL.indexOf('ms-') === 0) {
                           window.location.href = data.webdavURL;
                        } else if (data.viewURL) {
                           window.location.href = data.viewURL;
                        }
                     },
                     error: function(aResponse) {
                        errorUtil.handleAjaxFailure(aResponse);
                     }
                  });
               }
            }
         ];

         var modal = dialogUtil.showDialog({
            title: data.title,
            body: modalContent,
            buttons: buttons
         });

         var tags = getStoredValue() ? split(getStoredValue()) : [];

         modal.find('[data-file-tags]').select2({
            tags: tags,
            formatNoMatches: function () { return ''; },
            tokenSeparators: [',', ' '],
            openOnEnter: false
         });
      });
   });
});
