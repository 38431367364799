/**
* Copyright (C) Sitevision AB 2002-2025, all rights reserved
*
* Portlets using this utility must respond to portlet resource
* REST requests with the state parameter 'groupAdministration'.
*
* @author Karl Eklöf
*/

import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import {getModelObjectUri, getPortletResourceUri} from '../../../util/portletUtil';
import {
  Ajax as ajax,
  DialogUtil as dialogUtil,
  i18n as _i18n,
  KeyUtil as keyUtil,
} from '@sv/util';

const
  KEY = keyUtil.KEY,
  i18cache = {},
  i18n = function(selector, args) {
    var translated;

    if (!args && i18cache[selector]) {
      return i18cache[selector];
    }

    translated = _i18n.getText('portlet.social.util.addgrouputil', selector, args);

    if (!args) {
      i18cache[selector] = translated;
    }
    return translated;
  },
  i18nCommon = function(selector) {
    return _i18n.getText('common', selector);
  },
  defaults = {
    success: function() {},
    selected: function() {},
    placeholder: '',
    groupTemplates: [],
    groupFolder: null,
    hitTemplate: _.template('<li data-group-id="<%= id %>" class="sv-clearfix <% if (isMember) { %> sv-group-member<% } %>">'+
      '   <img class="sv-group-img sv-buddy-icon" src="<%= iconURL %>">'+
      '   <% if (isMember) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>'+
      '   <div class="sv-group-info-container">'+
      '     <h3 class="sv-group-name"><%- name %></h3>'+
      '     <p>'+
      '        <%= memberCount %> medlemmar'+
      '     </p>'+
      '   </div>'+
      '</li>')
  },
  createGroupTemplate = _.template('<form class="form-horizontal sv-fn-create-group-form">'+
    '    <div class="control-group">'+
    '       <label class="control-label">' +i18n('name') +'</label>'+
    '       <div class="controls">'+
    '          <input class="input-xlarge" data-fn-groupname type="text" name="name" required value="<%= name %>">'+
    '          <span class="help-block help">' +i18n('nameHelp') +'</span>'+
    '       </div>'+
    '    </div>'+
    '    <div class="control-group">'+
    '       <label class="control-label">' +i18n('description') +'</label>'+
    '       <div class="controls">'+
    '          <textarea class="input-xlarge" data-fn-groupdescription data-fn-request-focus rows="3" type="text" name="description"></textarea>'+
    '          <span class="help-block help">' +i18n('descriptionHelp') +'</span>'+
    '       </div>'+
    '    </div>'+
    '    <% if (templates.length > 1) { %>'+
    '    <div class="control-group">'+
    '       <label class="control-label">' +i18n('template') +'</label>'+
    '       <div class="controls">'+
    '          <select name="template">'+
    '            <% for(var idx in templates) { %>'+
    '               <option value="<%= templates[idx].id %>"><%= templates[idx].name %></option>' +
    '            <% } %>' +
    '          </select>'+
    '          <span class="help-block help">' +i18n('templateHelp') +'</span>'+
    '       </div>'+
    '    </div>'+
    '    <% } else { %>' +
    '    <input type="hidden" name="template" value="<%= templates[0].id %>">'+
    '    <% } %>'+
    '    <div class="control-group">'+
    '       <label class="control-label">' +i18n('type') +'</label>'+
    '       <div class="controls">'+
    '          <select name="type">'+
    '            <% for(var idx in groupTypes) { %>'+
    '               <option value="<%= groupTypes[idx].key %>"><%= groupTypes[idx].value %></option>' +
    '            <% } %>' +
    '          </select>'+
    '          <span class="help-block help">' +i18n('typeHelp') +'</span>'+
    '       </div>'+
    '    </div>'+
    ' </form>');

let searchTimer;

export const showSearchPopover = function(anOptions) {
  var options = $.extend({}, defaults, anOptions),
  $target = $(options.target),
  active = 0;

  if ($target.hasClass('sv-fn-popover-showing')) {
    $target.popover('destroy').removeClass('sv-fn-popover-showing');
    return;
  }

  var title = '<form class="form-search" onsubmit="return false;"><input type="text" placeholder="' +(options.placeholder || '') +'" data-fn-group-search class="span2 search-query"></form>';
  if (options.groupFolder) {
    title = '<form class="form-search" onsubmit="return false;"><div class="input-append"><input type="text" placeholder="' +(options.placeholder || '') +'" data-fn-group-search class="span1 search-query"><button type="button" class="btn btn-small sv-fn-create-group">' +i18n('createGroup') +'</button></div></form>';
  }

  $target.popover('destroy')
    .addClass('sv-fn-popover-showing')
    .popover({
      html: true,
      title: title,
      placement: 'bottom',
      content: '<ul class="sv-group-search-list sv-defaultlist"></ul>',
      trigger: 'manual'
    });

  $target.popover('show');

  // close if click outside popover
  var closeFunction = function(e) {
    if (!$(e.target).is('.popover *')) {
      $target.popover('destroy').removeClass('sv-fn-popover-showing');
      $('body').off('click', closeFunction);
    }
  };
  $('body').on('click', closeFunction);

  $target.parent().find('[data-fn-group-search]')
    .trigger('focus')
    .closest('.popover').addClass('sv-groupsearch-popover');

  $target.parent().find('.popover').on('click', '.sv-fn-create-group', function() {
    showCreateGroupDialog({
      name: $target.parent().find('[data-fn-group-search]').val(),
      folder: options.groupFolder,
      templates: options.groupTemplates,
      groupTypes: options.groupTypes,
      portletId : options.portletId
    });
  });

  var resultDiv = $target.parent().find('.popover-content').find('ul'),
  result = [];

  $target.parent().find('[data-fn-group-search]').on('keydown', function(e) {
    var keyCode = keyUtil.getKeyCodeFromEvent(e);

    if (keyCode === KEY.DOWN) {
      active++;
      if (active > result.length-1) {
        active = 0;
      }

      resultDiv.children()
        .removeClass('active')
        .eq(active).addClass('active');

      return false;
    } else if (keyCode === KEY.UP) {
      active--;
      if (active < 0) {
        active = result.length-1;
      }

      resultDiv.children()
        .removeClass('active')
        .eq(active).addClass('active');

      return false;
    }
  }).on('keyup', function(e) {
    var keyCode = keyUtil.getKeyCodeFromEvent(e);

    if (keyCode === KEY.ESC) { // esc close popover
      $target.popover('destroy');
      return false;
    } else if (keyCode === KEY.RETURN) {
      if (typeof (result[active]) !== 'undefined') {
        $target.popover('destroy');
        options.selected && options.selected.call(this, result[active]);
        return false;
      } else if (active === -1) {
        // show create dialog
        showCreateGroupDialog({
          name: $(this).val(),
          folder: options.groupFolder,
          template: options.groupTemplate,
          groupTypes: options.groupTypes,
          portletId: options.portletId
        });
      }
    } else if (keyUtil.isNonTextModifiableKeyCode(keyCode)) {
      return false;
    } else {
      var that = this;
      searchTimer && clearTimeout(searchTimer);
      searchTimer = setTimeout(function() {
        // reset result
        active = -1;
        resultDiv.empty();
        // do new search
        var term = $(that).val();
        ajax.doGet({
          url: getModelObjectUri(sv.PageContext.pageId, 'groupSearch', sv.PageContext.pageId),
          data: {
            term: term
          },
        }).done(function(aResult) {
          // Ensure result is still valid...
          if (aResult.queryString === $(that).val()) {
            active = 0;
            resultDiv.empty();
            result = aResult.hits;
            $.each(result, function(index, value) {
              var hit = $(options.hitTemplate(value));
              resultDiv.append(hit);
              hit.on('click', function() {
                $target.popover('destroy');
                options.selected && options.selected.call(this, value);
                return false;
              }).on('mouseenter', function() {
                resultDiv.children().removeClass('active');
                active = index;
                $(this).addClass('active');
              });
            });

            options.success && options.success.call(this, result);
          }
        });
      }, 200);
    }
  });
};

function translateArray(arr) {
  var i = 0, l = arr.length, result = [],
  key;

  for ( ; i < l; i ++) {
    key = arr[i];
    result.push({
      'key'    : key,
      'value'  : i18n(key)
    });
  }
  return result;
}

function serializeToObject($form) {
    var o = {};
    var a = $form.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
}

function showCreateGroupDialog(values) {
  values.groupTypes = translateArray(values.groupTypes);
  var modalContent = $(createGroupTemplate(values));

  var buttons = [
    {
      text: i18nCommon('cancel')
    },
    {
      text: i18nCommon('ok'),
      skipDismiss:true,
      primary: true,
      callback: function() {
        var createURL = getPortletResourceUri(values.portletId, 'groupAdministration');

        ajax.doPost({
          data: serializeToObject($('.sv-fn-create-group-form')),
          url : createURL,
          success: function(data) {
            $('.modal').modal('hide');
            location.href = data.showGroupURL;
          },
          error: function(data) { //post failed, show generic error message
            $('.alert').show();
            var result = JSON.parse(data.responseText);

            var title = $('.alert').find('.alert-title');
            title.data('orginal-title', title.text());
            var message = $('.alert').find('.alert-message');
            message.data('orginal-message', message.text());
            if (result.type) {
              message.text(i18n(result.type.toLowerCase()));
            }

            modalContent.find('input').first()
              .trigger('focus')
              .closest('.control-group').addClass('error');
          }
        });
      }
    }
  ];

  dialogUtil.showDialog({
    title: i18n('createGroupLabel'),
    body: modalContent,
    buttons: buttons,
    removeOnHide: true,
    errorTitle: i18n('createGroupError'),
    errorMsg: i18n('groupAlreadyExists')
  });
}


sv.AddGroupUtil = {
  showSearchPopover: showSearchPopover
};
