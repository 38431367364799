/*jshint nonew:false */
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import sv from '@sv/core';
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {getPortletResourceUri, getModelObjectUri, getTemplate} from '../../util/portletUtil';
import loadingImg from '../../util/static/img/loading_16_grey.gif';

import {
   Events as events,
   ObjectUtil as objectUtil,
   KeyUtil as keyUtil,
   DialogUtil as dialogUtil,
   i18n as _i18n,
   Ajax as ajax
 } from '@sv/util';

var
   KEY = keyUtil.KEY,
   i18cache = {},
   maxCharacterCount,
   inputConfig = {
      triggers: {
         '@': {
            source: getModelObjectUri(sv.PageContext.pageId, 'userIdentitySearch', sv.PageContext.pageId),
            searchKey: 'term',
            allowWhiteSpace: true,
            maxWhiteSpace: 2,
            parse: function(response) {
               return response.hits;
            }
         }
      },
      minLength: 2,
      replacer: function(item) {
         return item.trigger + '{"value":"' + item.name + '","id":"' + item.id + '"}';
      }
   };

// HELPERS ----------------------------------------------------
var i18n = function(selector, args) {
   var translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.fileview.file', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};

var fixMentionLinks = function($el, profilePageURL) {
   $el.find('a[data-user-identity]').each(function() {
      var $this = $(this);
      $this.attr('href', profilePageURL + '?identity=' + $this.data('user-identity'));
   });
};

var fixTagLinks = function($el, tagResultPageURL) {
   $el.find('a[data-social-tag-id]').each(function() {
      var $this = $(this);
      $this.attr('href', tagResultPageURL + '?tag=' + encodeURIComponent($this.data('social-tag-name')));
   });
};


var Comment = Backbone.Model.extend({
   defaults: {
      removeTitle: i18n('removeCommentTitle'),
      editTitle: i18n('editCommentTitle'),
      replyLabel: i18n('replyLabel')
   },

   url: function() {
      if (this.collection) {
         return getPortletResourceUri(this.collection.options.portletId, 'comment') +
            '&file=' + this.collection.options.id + '&commentId=' + this.get('id');
      }

      return '?commentId=' + this.get('id');
   }

});

var Comments = Backbone.Collection.extend({
   model: Comment,

   initialize: function(collection, options) {
      this.options = options;
   },

   url: function() {
      return getPortletResourceUri(this.options.portletId, 'comment') + '&file=' + this.options.id;
   }

});

var CommentView = Backbone.View.extend({
   tagName: 'li',

   className: 'sv-file-comment',

   events: {
      'click [data-fn-destroy-comment]': 'showDestroyDialog',
      'click [data-fn-edit-comment]'   : 'editComment'
   },

   initialize: function() {
      this.model.on('change:comment', this.updateComment, this);
      this.model.on('destroy', this.remove, this);
   },

   showDestroyDialog: function() {
      var that = this;

      if (!this.model.get('isRemovable')) {
         return false;
      }

      dialogUtil.showConfirmDialog(
         i18n('removeCommentTitle'),
         i18n('removeCommentBody'),
         function(success) {
            if (success) {
               that.model.destroy({
                  wait:true
               });
            }
         });
   },

   editComment: function(e) {
      e.preventDefault();

      if (!this.model.get('isEditable')) {
         return false;
      }

      ajax.doGet({
         context: this,
         url: getPortletResourceUri(this.options.portletId, 'comment') +
                                 '&file=' + this.model.collection.options.id + '&commentId=' + this.model.get('id'),
      }).done(function(response) {
         this.showEditComment(response.plainMessage, response.mentions);
      });
   },

   showEditComment: function(message, mentions) {
      var that = this,
         fileId = this.model.collection.options.id,
         commentId = this.model.get('id'),
         modalContent = $('<div/>');

      var $charactersLeft = $('<span class="sv-float-right">' + (maxCharacterCount - message.length) + '</span>');

      var $commentField = $('<textarea/>')
         .attr('id', 'editComment')
         .addClass('sv-comment-input sv-border-box')
         .on('keyup', _.bind(function(e) {
            var charactersLeft = maxCharacterCount - e.currentTarget.value.length;

            if (charactersLeft < 0) {
               $charactersLeft.addClass('sv-character-limit-exceeded');
               this.getSaveEditCommentButton()
                  .addClass('disabled')
                  .attr('disabled', 'disabled');
            } else {
               $charactersLeft.removeClass('sv-character-limit-exceeded');
               this.getSaveEditCommentButton()
                  .removeClass('disabled')
                  .prop('disabled', false);
            }

            $charactersLeft.text(charactersLeft);
         }, this))
         .val(message);

      modalContent
         .addClass('sv-fileshareall-portlet')
         .append($commentField)
         .append($charactersLeft);

      var buttons = [ {
         text: i18n('save'),
         callback: $.proxy(function() {
            ajax.doPut({
               url: getPortletResourceUri(that.options.portletId, 'comment') +
                     '&file=' + fileId + '&commentId=' + commentId,
               data: {
                  comment: $commentField.triggeredInput('getRichContent'),
               },
               context: this
            }).done(function(updatedComment) {
              this.model.set(updatedComment);
            });
         }, this)
      }];

      dialogUtil.showDialog({
         title: i18n('editCommentTitle'),
         body: modalContent,
         buttons: buttons,
         shownCallback: function() {
            $commentField
               .triggeredInput(inputConfig)
               .triggeredInput('setIds', mentions)
               .elastic();
         }
      });

      return false;
   },

   getSaveEditCommentButton: function() {
      if (!this.$saveEditButton) {
         this.$saveEditButton = $('.modal-footer button');
      }

      return this.$saveEditButton;
   },

   updateComment: function() {
      this.render();
      events.trigger(events.types.updateLikables, this.$el);
      events.trigger(events.types.updateRelativeDates, this.$el);
   },

   render: function() {
      this.$el.html(this.options.template(this.model.toJSON()));

      fixMentionLinks(this.$el, this.options.profilePageURL);
      fixTagLinks(this.$el, this.options.tagResultPageURL);

      return this;
   }
});

var CommentsView = Backbone.View.extend({

   initialize: function() {
      this.$list = this.$el.find('[data-fn-comments]');
      this.collection.on('add', this.addNewComment, this);
      this.collection.on('add', this.updateCommentCount, this);
      this.collection.on('destroy', this.updateCommentCount, this);

      this.getTextField().triggeredInput(inputConfig).elastic();
      this.getTextField().css('height', '20px');

      if (this.collection.length > 0) {
         this.render();
      }
   },

   render: function() {
      this.clearList();
      this.collection.each(function(comment) {
         this.appendOne(comment);
      }, this);

      events.trigger(events.types.updateRelativeDates, this.$el);
      events.trigger(events.types.updateLikables, this.$el);
      events.trigger(events.types.updateOembedLinks, this.$el);
   },

   clearList: function() {
      this.$list.empty();
   },

   updateCommentCount: function() {
      var counter = this.$el.find('[data-fn-message-comment-count]');

      counter.text(this.collection.length);
   },

   appendOne: function(comment) {
      var view = new CommentView({
         model: comment,
         template: this.options.commentTemplate,
         portletId: this.options.portletId,
         profilePageURL: this.options.profilePageURL,
         tagResultPageURL: this.options.tagResultPageURL
      });

      var commentEl = view.render().$el;
      var commentContentObj = commentEl.find('.sv-message-content'); //Should prolly be .sv-comment-content
      var truncate = commentContentObj.find('.sv-truncate-more'),
         split;

      if (truncate.length > 0) {
         this.addShowMoreLink(truncate);
         split = true;
      }

      this.$list.append(commentEl);

      if (split) {
         this.bindShowMoreEvents(commentEl);
      }
   },

   bindShowMoreEvents: function(obj) {
      var options = {
         moreText: i18n('showMore'),
         lessText: i18n('showLess')
      };
      var moreLink = $('.sv-truncate-more-link', obj),
         moreContent = $('.sv-truncate-more', obj),
         ellipsis = $('.sv-truncate-ellipsis', obj);

      moreLink.on('click', function(e) {
         e.preventDefault();
         if(moreLink.text() === options.moreText) {
            moreContent.show();
            moreLink.text(options.lessText);
            ellipsis.css('display', 'none');
         } else {
            moreContent.hide();
            moreLink.text(options.moreText);
            ellipsis.css('display', 'inline');
         }
      });
   },

   addShowMoreLink: function(obj) {
      $('<span class="sv-truncate-ellipsis">...</span>').insertBefore(obj);
      $('<div>' + '<a href="#" class="sv-truncate-more-link">' + i18n('showMore') + '</a>' + '</div>').insertAfter(obj);
      obj.css('display', 'none');
   },

   addNewComment: function(entry) {
      this.appendOne(entry);

      events.trigger(events.types.updateRelativeDates, this.$el);
      events.trigger(events.types.updateLikables, this.$el);
   },

   getLoader: function() {
      if (!this.$loader) {
         this.$loader = $('<img src="' + loadingImg + '">')
            .css('vertical-align', 'middle')
            .appendTo(this.$el.find('[data-fn-comment-input]').parent());
      }

      return this.$loader;
   },

   countdownCharactersLeft: function(e) {
      var $target = $(e.currentTarget),
         text = $target.val(),
         size = maxCharacterCount - text.length,
         sizeField = this.$el.find('[data-fn-comment-input-size]');

      if (size < 0) {
         sizeField.addClass('sv-character-limit-exceeded');
      } else {
         sizeField.removeClass('sv-character-limit-exceeded');
      }

      sizeField.text(size);

      if (text !== '' && size >= 0 && !this.activeConnection) {
         this.getSubmitButton()
            .removeClass('disabled')
            .prop('disabled', false);
      } else {
         this.getSubmitButton()
            .addClass('disabled')
            .attr('disabled', 'disabled');
      }
   },

   getSubmitButton: function() {
      if (!this.$submitButton) {
         this.$submitButton = this.$el.find('[data-fn-comment-submit]');
      }
      return this.$submitButton;
   },

   getTextField: function() {
      if (!this.$textField) {
         this.$textField = this.$el.find('[data-fn-comment-input]');
      }
      return this.$textField;
   },

   getSubmitControls: function() {
      if (!this.$submitControls) {
         this.$submitControls = this.$el.find('.sv-submit-controls');
      }
      return this.$submitControls;
   },

   events: {
      'click [data-fn-share]'             : 'shareEntry',
      'keyup [data-fn-comment-input]'     : 'countdownCharactersLeft',
      'click [data-fn-comment-submit]'    : 'postComment',
      'keydown [data-fn-comment-input]'   : 'expandTextfield'
   },

   checkKeyDown: function(e) {
      var key = keyUtil.getKeyCodeFromEvent(e);
      if (key === KEY.RETURN && e.ctrlKey) {
         this.postComment(e);
      }
   },

   expandTextfield: function(e) {
      $(e.currentTarget).addClass('sv-comment-input-expanded');
      this.getSubmitControls().removeClass('env-d--none').show();
      this.countdownCharactersLeft(e);
      this.checkKeyDown(e);
   },

   postComment: function(e) {
      var that = this,
         field, comment, commentLength,
         loaderTimeout;

      e.preventDefault(); // Make sure form isn't POSTed
      if (this.activeConnection) {
         return false;
      }
      field = this.getTextField();
      commentLength = field.val().length;

      if (commentLength > 0 && commentLength <= maxCharacterCount) {
         this.activeConnection = true;
         comment = new Comment({
            comment: field.triggeredInput('getRichContent')
         });

         loaderTimeout = setTimeout(function() {
            that.getLoader().show();
         }, 400);

         this.collection.create(comment, {
            wait: true,
            success: function() {
               field.val('').css('height', '20px');
               that.activeConnection = false;
               that.getLoader().hide();
               clearTimeout(loaderTimeout);
               that.getSubmitControls().hide();
            },
            error: function() {
               that.activeConnection = false;
               that.getLoader().hide();
               clearTimeout(loaderTimeout);
            }
         });
      } else if (commentLength > maxCharacterCount) {
         events.trigger(events.types.notifyUser, {
            'type': 'default',
            'heading': i18n('commentToLongHeading'),
            'message': i18n('commentToLongBody'),
            'transient': true
         });
      }
      return false;
   },

   shareEntry: function() {
      var that = this,
         modalContent = $('<div/>')
            .addClass('sv-timelineentry-portlet'),
         timelineWrapper = $('<div/>')
            .addClass('sv-timline'),
         message = $('<blockquote/>')
            .addClass('sv-clearfix sv-timeline-entry')
            .css({
               'margin': '1em 0',
               'padding': '0',
               'border': 'none'
            }),
         originalMessage;

      var sharedEntry = this.$el.find('[data-fn-shared-entry]');
      if (sharedEntry.length > 0) {
         originalMessage = sharedEntry;
      } else {
         originalMessage = this.$el;
      }

      message
         .append(originalMessage.find('a').first().clone())
         .append(originalMessage.find('h3,h4').first().clone().css('margin', '0'))
         .append(originalMessage.find('p').first().clone());

      message.find('img').css({
         'margin-right': '1em',
         'width': '48px'
      });

      timelineWrapper.append(message);
      modalContent.append(timelineWrapper);

      var label = $('<label/>').attr('for', 'sharecomment').text(i18n('shareComment'));
      modalContent.append(label);

      var messageField = $('<textarea/>')
         .attr('id', 'sharecomment')
         .addClass('sv-timeline-input sv-border-box')
         .css('width', '100%');
      modalContent.append(messageField);

      var buttons = [ {
         text: i18n('share'),
         callback: function() {
            // do share
            $.ajax({
               type: 'POST',
               dataType: 'JSON',
               url: getPortletResourceUri(that.options.portletId, 'shareTimelineEntry'),
               data: {
                  message: messageField.val(),
                  entry: sharedEntry.length > 0 ? sharedEntry.data('entry-id') : this.collection.options.id
               },
               done: function() {
                  events.trigger(events.types.notifyUser, {
                     'type'      : 'success',
                     'heading'   : i18n('sharedMessageTitle'),
                     'message'   : i18n('sharedMessageContent')
                  });
               }
            });
         }
      }];

      dialogUtil.showDialog({
         title: i18n('shareEntry'),
         body: modalContent,
         buttons: buttons
      });

      return false;
   }
});

$('.sv-fileshareall-portlet').each(function () {
   import(/* webpackChunkName: "fileview-plugins" */ './plugins').then(
     () => {
      var $portlet = $(this),
         $fileEntry = $portlet.find('[data-fn-file-entry]');

      if ($fileEntry.length === 0) {
         return;
      }

      var portletId = objectUtil.getObjectId($portlet.attr('id')),
         jsCommentsNamespace = $fileEntry.data('js-comments-namespace'),
         commentableId = $fileEntry.data('file-id'),
         profilePageURL = $fileEntry.data('profilepageurl'),
         tagResultPageURL = $fileEntry.data('tagresultpageurl'),
         comments = new Comments(sv[jsCommentsNamespace].comments || [], {
            id: commentableId,
            portletId: portletId
         }),
         $fileCommentsContainer;

         maxCharacterCount = sv[jsCommentsNamespace].maxCharacterCount;

         new CommentsView({
            el: $portlet,
            collection: comments,
            commentTemplate: getTemplate($portlet, 'file-comment'),
            portletId: portletId,
            profilePageURL: profilePageURL,
            tagResultPageURL: tagResultPageURL
         });

      $fileCommentsContainer = $portlet.find('.sv-file-comments');

      if ($fileCommentsContainer.length > 0) {
         fixMentionLinks($fileCommentsContainer, profilePageURL);
         fixTagLinks($fileCommentsContainer, tagResultPageURL);
      }
   });
});
