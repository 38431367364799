/**
 * Copyright (C) Sitevision AB 2002-2022, all rights reserved
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import loadingImg from '../../../util/static/img/loading_16_grey.gif';
import {
  getPortletResourceUri,
  getModelObjectUri,
  reloadPortletsByType,
} from '../../../util/portletUtil';
import { getHashValue, hasHashValue } from '../../../util/windowLocationUtil';
import {
  Events as events,
  KeyUtil as keyUtil,
  ErrorUtil as errorUtil,
  Ajax as ajax,
} from '@sv/util';
import {
  inputConfig,
  isMaliciousFileType,
  i18nTimeline,
  verifyFileUpload,
  truncateFileName,
} from '../utils';
import TimelineEntryView from './TimelineEntry';
import TimelineEntryModel from '../model/TimelineEntry';

let searchTimer;
const KEY = keyUtil.KEY;

const Timeline = Backbone.View.extend({
  initialize: function () {
    this.$list = this.$el.find('ol[data-fn-timeline]');
    this.bindCollectionEvents();

    this.bindPlugins();

    var that = this;
    events.on(events.types.groupPageViewed, function () {
      that.$list.find('.sv-new').removeClass('sv-new');
    });

    if (this.collection.length > 0) {
      this.render();
    }

    if (this.options.isTimelineSearch) {
      this.handleState();
    }
  },

  handleState: function () {
    this.checkForHashValue();

    window.addEventListener(
      'popstate',
      function () {
        this.checkForHashValue();
      }.bind(this)
    );
  },

  checkForHashValue: function () {
    if (hasHashValue()) {
      var query = decodeURI(getHashValue('query')),
        $searchInput = this.getSearchInput();

      if (query !== $searchInput.val()) {
        $searchInput.val(query);
        this.searchEntries();
      }
    }
  },

  bindPlugins: function () {
    const $dropzone = this.$el.find('[data-fn-message-form]');

    this.uploadedFile = [];
    this.getTextField().triggeredInput(inputConfig).elastic();

    this.$el.find('[data-fn-message-form] input.sv-file-input').fileupload({
      dataType: 'json',
      url: getModelObjectUri(this.options.timelineOwner, 'timelineFileUpload'),
      replaceFileInput: true,
      formAcceptCharset: 'utf-8',
      dropZone: $dropzone,

      submit: (e, data) => {
        var file = data.files[0],
          fileSize = file.size;

        if (
          this.uploadedFile.filter(
            (uploadedFiles) => uploadedFiles.originalName === file.name
          ).length
        ) {
          return false;
        }

        if (isMaliciousFileType(file.name)) {
          events.trigger(events.types.notifyUser, {
            type: 'error',
            heading: i18nTimeline('maliciousFileTypeTitle'),
            message: i18nTimeline('maliciousFileTypeMessage'),
          });
          return false;
        }

        // Make sure that the file that is about to get uploaded is accepted
        if (
          !verifyFileUpload(
            file.name,
            fileSize,
            this.options.timelineOwner,
            this.options.portletId
          )
        ) {
          return false;
        }

        this.$el.find('[data-fn-file-loading]').show();
        this.disableTextField();
      },

      done: (e, data) => {
        const submittedFileName = data.result.result.originalName;
        const truncatedFileName =
          submittedFileName.length > 30
            ? truncateFileName(submittedFileName)
            : submittedFileName;

        this.uploadedFile.push(data.result.result);
        this.$el.find('[data-files-added]').append(
          `<div class="sv-file-added env-d--flex" data-fn-file-added data-fileId="${
            data.result.result.id
          }">
            <a class="close sv-remove-attachment env-m-right--xx-small" data-fn-destroy-file title="${i18nTimeline(
              'removeAttachment'
            )}" href="#">&times;</a>
            <span data-fn-filename>${_.escape(truncatedFileName)}</span>
            </div>`
        );

        this.$el.find('[data-files-added]').show();
        this.$el.find('[data-fn-file-loading]').hide();

        this.$el
          .find('.sv-amount-of-files')
          .show()
          .text(` (${this.uploadedFile.length})`);

        this.enableTextField();
        this.getTextField().trigger('focus');
      },
    });
  },

  resetAttachmentField: function () {
    this.$el.find('[data-fn-file-added]').remove();
    this.$el.find('[data-fn-file-loading]').hide();
  },

  bindCollectionEvents: function () {
    this.listenTo(this.collection, 'add', this.addOne);
    this.listenTo(this.collection, 'reset', this.render);
  },

  render: function () {
    this.clearList();
    this.collection.each(function (item) {
      if (!item.get('removed')) {
        this.appendOne(item);
      }
    }, this);
  },

  addOne: function (entry, collection, options) {
    if (options.append) {
      this.appendOne(entry);
    } else {
      this.prependOne(entry);
    }
  },

  prependOne: function (entry) {
    var view = new TimelineEntryView({
      model: entry,
      template: this.options.timelineEntryTemplate,
      portletId: this.options.portletId,
      profilePageURL: this.options.profilePageURL,
      tagResultPageURL: this.options.tagResultPageURL,
      allowEmojis: this.options.allowEmojis,
      maxCharacterCount: this.options.maxCharacterCount,
      userIdentity: this.options.userIdentity,
      requireActiveGroup: this.options.requireActiveGroup,
      getEmojiPicker: this.options.getEmojiPicker,
    });

    var entryEl = view.render().$el;
    var entryContentObj = entryEl.find('.sv-message-content');
    var truncate = entryContentObj.find('.sv-truncate-more'),
      split;

    if (truncate.length > 0) {
      this.addShowMoreLink(truncate);
      split = true;
    }

    this.$list.prepend(entryEl);
    if (split) {
      this.bindShowMoreEvents(entryEl);
    }

    if (this.options.useOembed) {
      events.trigger(events.types.updateOembedLinks, entryEl);
    }
  },

  appendOne: function (entry) {
    var view = new TimelineEntryView({
      model: entry,
      template: this.options.timelineEntryTemplate,
      portletId: this.options.portletId,
      profilePageURL: this.options.profilePageURL,
      tagResultPageURL: this.options.tagResultPageURL,
      allowEmojis: this.options.allowEmojis,
      maxCharacterCount: this.options.maxCharacterCount,
      userIdentity: this.options.userIdentity,
      requireActiveGroup: this.options.requireActiveGroup,
      getEmojiPicker: this.options.getEmojiPicker,
    });

    var entryEl = view.render().$el;
    var entryContentObj = entryEl.find('.sv-message-content');
    var truncate = entryContentObj.find('.sv-truncate-more'),
      split;

    if (truncate.length > 0) {
      this.addShowMoreLink(truncate);
      split = true;
    }

    this.$list.append(entryEl);
    if (split) {
      this.bindShowMoreEvents(entryEl);
    }

    if (this.options.useOembed) {
      events.trigger(events.types.updateOembedLinks, entryEl);
    }
  },

  bindShowMoreEvents: function ($obj) {
    var options = {
      moreText: i18nTimeline('showMore'),
      lessText: i18nTimeline('showLess'),
    };

    var $moreLink = $('.sv-truncate-more-link:first', $obj),
      $moreContent = $('.sv-truncate-more:first', $obj),
      $ellipsis = $('.sv-truncate-ellipsis:first', $obj);

    $moreLink.on('click', function (e) {
      e.preventDefault();
      if ($moreLink.text() === options.moreText) {
        $moreContent.show();
        $moreLink.text(options.lessText);
        $ellipsis.css('display', 'none');
      } else {
        $moreContent.hide();
        $moreLink.text(options.moreText);
        $ellipsis.css('display', 'inline');
      }
    });
  },

  addShowMoreLink: function (obj) {
    $('<span class="sv-truncate-ellipsis">...</span>').insertBefore(obj);
    $(
      '<div>' +
        '<a href="#" class="sv-truncate-more-link">' +
        i18nTimeline('showMore') +
        '</a>' +
        '</div>'
    ).insertAfter(obj);
    obj.css('display', 'none');
  },

  appendAll: function (timeline) {
    timeline.each(this.appendOne, this);
  },

  clearList: function () {
    this.$list.empty();
  },

  events: {
    'click [data-fn-message-input]': 'expandTextfield',
    'focus [data-fn-message-input]': 'expandTextfield',
    'keyup [data-fn-message-input]': 'countdownCharactersLeft',
    'keydown [data-fn-message-input]': 'checkKeyDown',
    'submit [data-fn-message-form]': 'addNewEntry',
    'click [data-fn-load-more]': 'loadMoreEntries',
    'click [data-fn-destroy-file]': 'destroyFile',
    'input [data-fn-search-entries]': 'handleSearch',
    'click [data-entry-emoji-button]': 'handleEmoji',
    'keydown [data-entry-emoji-button]': 'checkEmojiKeyDown',
    'submit [data-fn-search-entries-form]': 'preventFormSubmit',
  },

  checkEmojiKeyDown: function (e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.RETURN) {
      this.handleEmoji();
    }
  },

  handleEmoji: function () {
    var picker = this.options.getEmojiPicker('right-start'),
      $inputField = this.getTextField(),
      $button = this.getEmojiButton();

    picker.on('emoji', function (emoji) {
      var field = $inputField[0],
        selectionStart = field.selectionStart,
        selectionEnd = field.selectionEnd,
        inputValue = $inputField.val(),
        startString = inputValue.substring(0, selectionStart),
        endString = inputValue.substring(selectionEnd, inputValue.length);

      $inputField.val(startString + emoji + endString);
      $inputField.trigger('focus');
    });

    picker.pickerVisible ? picker.hidePicker() : picker.showPicker($button);
  },

  destroyFile: function (e) {
    const fileElement = e.currentTarget.parentElement;
    const fileId = fileElement.dataset.fileid;

    $.ajax({
      url:
        getModelObjectUri(this.options.timelineOwner, 'timelineFileUpload') +
        '/' +
        fileId,
      type: 'DELETE',
    });

    this.uploadedFile = this.uploadedFile.filter(function (file) {
      return file.id != fileId;
    });

    if (this.uploadedFile.length) {
      this.$el
        .find('.sv-amount-of-files')
        .show()
        .text(` (${this.uploadedFile.length})`);
    } else {
      this.$el.find('.sv-amount-of-files').hide();

      if (this.getTextField().val()) {
        this.enableTextField();
      } else {
        this.disableTextField();
      }
    }

    $(fileElement).remove();
    return false;
  },

  preventFormSubmit: function () {
    return false;
  },

  handleSearch: function () {
    searchTimer && clearTimeout(searchTimer);
    searchTimer = setTimeout(this.searchEntries.bind(this), 200);
    return false;
  },

  searchEntries: function () {
    var searchInput = this.getSearchInput().val(),
      query = searchInput ? searchInput.trim() : '';

    if (!query || query.length === 0) {
      this.collection.reset();
      this.getLoadMoreButton().addClass('env-d--none');
      this.getNoHitsQueryContainer().addClass('env-d--none');

      if (window.history.pushState && hasHashValue()) {
        window.history.pushState(
          null,
          null,
          window.location.pathname + window.location.search
        );
      }
    } else {
      var url = getPortletResourceUri(this.options.portletId, 'searchEntries'),
        data = {
          timelineOwner: this.options.timelineOwner,
          page: 0,
          searchQuery: query,
        };

      this.getEntries(url, data).done(function (response) {
        this.collection.reset(response);
        this.collection.page = 0;

        if (window.history.pushState) {
          window.history.pushState(null, null, '#query/' + query);
        }

        if (this.collection.length === this.options.loadMoreCount) {
          this.activateLoadMoreButton();
        } else if (this.collection.length < this.options.loadMoreCount) {
          this.getLoadMoreButton().addClass('env-d--none');
        }

        if (response.length === 0) {
          this.getNoHitsQueryContainer()
            .removeClass('env-d--none')
            .find('strong')
            .text(query);
        } else {
          this.getNoHitsQueryContainer().addClass('env-d--none');
        }
      });
    }
  },

  getEntries: function (url, data) {
    return ajax.doGet({
      url: url,
      data: data,
      context: this,
    });
  },

  loadMoreEntries: function () {
    var actionName = this.options.isTimelineSearch
        ? 'searchEntries'
        : 'timelineEntry',
      url = getPortletResourceUri(this.options.portletId, actionName),
      data = this.getLoadMoreData(this.options.isTimelineSearch);

    this.getEntries(url, data).done(function (response) {
      this.collection.add(response, { append: true });

      if (response.length < this.options.loadMoreCount) {
        this.disableLoadMoreButton();
      }
    });
  },

  disableLoadMoreButton: function () {
    this.getLoadMoreButton()
      .addClass('disabled')
      .prop('disabled', true)
      .text(i18nTimeline('noMoreEntries'));
  },

  activateLoadMoreButton: function () {
    this.getLoadMoreButton()
      .removeClass('disabled env-d--none')
      .prop('disabled', false)
      .text(i18nTimeline('loadEntries'));
  },

  getLoadMoreData: function (isTimelineSearch) {
    var data = {
      timelineOwner: this.options.timelineOwner,
    };

    if (isTimelineSearch) {
      this.collection.page += 1;

      var searchInput = this.getSearchInput().val();

      return _.extend(data, {
        page: this.collection.page,
        searchQuery: searchInput ? searchInput.trim() : '',
      });
    }

    return _.extend(data, {
      lastEntryId: this.collection.at(this.collection.length - 1).get('id'),
    });
  },

  getSearchInput: function () {
    if (!this.$searchInput) {
      this.$searchInput = this.$el.find('[data-fn-search-entries]');
    }

    return this.$searchInput;
  },

  getLoadMoreButton: function () {
    if (!this.$loadMoreButton) {
      this.$loadMoreButton = this.$el.find('button[data-fn-load-more]');
    }

    return this.$loadMoreButton;
  },

  getNoHitsQueryContainer: function () {
    if (!this.$noHitsQueryContainer) {
      this.$noHitsQueryContainer = this.$el.find('#sv-no-hits-query');
    }

    return this.$noHitsQueryContainer;
  },

  getSubmitButton: function () {
    if (!this.$submitButton) {
      this.$submitButton = this.$el.find('[data-fn-message-submit]');
    }

    return this.$submitButton;
  },

  getLoader: function () {
    if (!this.$loader) {
      this.$loader = $(
        '<img src="' + loadingImg + '">'
      )
        .css('vertical-align', 'middle')
        .appendTo(this.getSubmitButton().parent());
    }

    return this.$loader;
  },

  getTextField: function () {
    if (!this.$textField) {
      this.$textField = this.$el.find('[data-fn-message-input]');
    }

    return this.$textField;
  },

  isDisabled: function () {
    return this.getSubmitButton().hasClass('disabled');
  },

  disableTextField: function () {
    this.getSubmitButton().addClass('disabled').attr('disabled', 'disabled');
  },

  enableTextField: function () {
    var textField = this.getTextField();
    if (textField.val() !== '' || this.uploadedFile) {
      this.getSubmitButton().removeClass('disabled').prop('disabled', false);
    }
  },

  addNewEntry: function () {
    var that = this,
      inputField = this.getTextField(),
      message = inputField.val(),
      size = this.options.maxCharacterCount - message.length,
      timelineEntry,
      firstEntry = this.collection.length === 0,
      loaderTimeout;

    if ((message !== '' && size >= 0) || this.uploadedFile) {
      if (
        !this.options.requireActiveGroup() ||
        this.isDisabled() ||
        this.activeConnection
      ) {
        return false;
      }

      loaderTimeout = setTimeout(function () {
        that.getLoader().show();
      }, 400);

      this.disableTextField();
      this.activeConnection = true;

      const fileList = [];
      if (that.uploadedFile) {
        that.uploadedFile.forEach(function (file) {
          fileList.push(file.id);
        });
      }

      timelineEntry = new TimelineEntryModel({
        message: inputField.triggeredInput('getRichContent'),
        files: that.uploadedFile ? fileList.join(',') : undefined,
      });

      this.collection.create(timelineEntry, {
        wait: true,
        clearTextField: true,
        clearUploadedFile: true,
        success: function () {
          var counter = that.$el.find('[data-fn-message-input-size]'),
            initialSize = counter.data('initial-size');
          that.getTextField().val('');

          if (that.uploadedFile) {
            events.trigger(events.types.fileAdded);
          }
          if (firstEntry) {
            reloadPortletsByType('.sv-profileprogress-portlet');
          }

          clearTimeout(loaderTimeout);
          that.getLoader().hide();
          that.uploadedFile = [];
          that.resetAttachmentField();
          counter.text(initialSize);
          that.activeConnection = false;
          that.collection.trigger('markAsRead');

          that.$el.find('.sv-amount-of-files').hide();
        },
        error: function (response) {
          that.getLoader().hide();
          that.enableTextField();
          that.activeConnection = false;
          errorUtil.handleAjaxFailure(response);
        },
      });
    }

    return false;
  },

  expandTextfield: function (e) {
    $(e.currentTarget).addClass('sv-message-input-expanded');
    this.getSubmitControls().removeClass('env-d--none').show();
    this.countdownCharactersLeft(e);
  },

  getEmojiButton: function () {
    if (!this.$emojiButton) {
      this.$emojiButton = this.$el.find('[data-entry-emoji-button]');
    }
    return this.$emojiButton;
  },

  getSubmitControls: function () {
    if (!this.$submitControls) {
      this.$submitControls = this.$el.find('.sv-submit-message-controls');
    }
    return this.$submitControls;
  },

  checkKeyDown: function (e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.RETURN && e.ctrlKey) {
      this.$el.find('[data-fn-message-form]').trigger('submit');
      return false;
    }
  },

  countdownCharactersLeft: function (e) {
    var $target = $(e.currentTarget),
      text = $target.val(),
      size = this.options.maxCharacterCount - text.length,
      sizeField = this.$el.find('[data-fn-message-input-size]');

    if (size < 0) {
      sizeField.addClass('sv-character-limit-exceeded');
    } else {
      sizeField.removeClass('sv-character-limit-exceeded');
    }

    sizeField.text(size);

    if (
      ((text.trim() !== '' && size >= 0) || this.uploadedFile.length > 0) &&
      !this.activeConnection
    ) {
      this.enableTextField();
    } else {
      this.disableTextField();
    }
  },
});

export default Timeline;
