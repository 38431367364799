/*jshint nonew:false */
/**
 * Copyright (C) SiteVision AB 2002-2025, all rights reserved
 *
 * @author Karl Eklöf
 */
import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import moment from '../../vendor/moment';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import {
  ActivityDetailView,
  ActivityEditView,
  ActivityModel,
  stripYear,
} from '../util/activityUtil';
import {
  Events as events,
  ObjectUtil as objectUtil,
  DialogUtil as dialogUtil,
  i18n as _i18n,
  KeyUtil as keyUtil,
  ClientUtil as clientUtil,
} from '@sv/util';

var filter = 'all', // default filter settings must match the default in tasks.vm (i.e. match the icon)
  i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  },
  i18n = function (key, args) {
    return _i18n.getText('portlet.social.tasks.tasks', key, args);
  };

// HELPERS -----------------------------------------------------------------
var removeActivityInternal = function (onClose) {
  var that = this;
  $('.modal').modal('hide');
  if (this.model.get('start')) {
    // i.e. is the task in the calendar
    dialogUtil.showDialog({
      title: i18n('confirmRemoveCalendarTitle'),
      body: i18n('confirmRemoveCalendarMessage'),
      buttons: [
        {
          text: i18nCommon('cancel'),
          callback: function () {
            $.proxy(onClose, that)();
          },
        },
        {
          text: i18n('confirmRemoveCalendarRemove'),
          callback: function () {
            that.model.destroy({
              success: function (model) {
                // set data to be able to DnD tasks to the calendar (see calendar-portlet.js)
                events.trigger(events.types.activityDeleted, model.attributes);
              },
            });
            $.proxy(onClose, that)();
          },
        },
        {
          text: i18n('confirmRemoveCalendarSaveInCalendar'),
          callback: function () {
            that.model.set('isTask', false);
            that.model.save(null, {
              success: function (model) {
                events.trigger(events.types.activityUpdated, model.attributes);
              },
            });
            $.proxy(onClose, that)();
          },
        },
      ],
      errorTitle: i18nCommon('networkErrorTitle'),
      errorMsg: i18nCommon('networkErrorText'),
    });
  } else {
    dialogUtil.showConfirmDialog(
      i18n('confirmRemoveTitle'),
      i18n('confirmRemoveMessage'),
      function (result) {
        if (result) {
          that.model.destroy({
            success: function (model) {
              // set data to be able to DnD tasks to the calendar (see calendar-portlet.js)
              events.trigger(events.types.activityDeleted, model.attributes);
            },
          });
          $.proxy(onClose, that)();
        }
      }
    );
  }
  return false;
};

// MODEL -------------------------------------------------------------------
var Task = ActivityModel.extend({
  allowEmptyStart: true,

  url: function () {
    if (this.collection) {
      return (
        this.collection.url() + (this.has('id') ? '&id=' + this.get('id') : '')
      );
    }
    return (
      getPortletResourceUri(this.portletId, 'task') +
      (this.has('id') ? '&id=' + this.get('id') : '')
    );
  },
});

// COLLECTION --------------------------------------------------------------
var TaskList = Backbone.Collection.extend({
  model: Task,

  comparator: function (task1, task2) {
    var start1 = task1.get('start'),
      created1 = task1.get('createdAt'),
      start2 = task2.get('start'),
      created2 = task2.get('createdAt');

    if (!start1) {
      if (!start2) {
        return created1 >= created2 ? -1 : 1;
      }
      return -1;
    }
    if (!start2) {
      return 1;
    }
    if (start1 === start2) {
      return created1 >= created2 ? -1 : 1;
    }
    return start1 > start2 ? -1 : 1;
  },

  initialize: function (models, options) {
    this.portletId = options.portletId;
  },

  url: function () {
    return getPortletResourceUri(this.portletId, 'task');
  },
});

// DIALOG VIEW -------------------------------------------------------------
var TaskEditView = ActivityEditView.extend({
  templateName: 'edit',

  memberTemplateName: 'member',

  participantTemplateName: 'participant',

  membersActionName: 'members',

  membersWithOwnerActionName: 'membersWithOwner',

  i18n: i18n,

  removeActivity: function () {
    $.proxy(removeActivityInternal, this, function () {
      $('.modal').modal('hide');
    })();
  },
});

// POPOVER VIEW ------------------------------------------------------------
var TaskDetailView = ActivityDetailView.extend({
  templateName: 'details',

  participantsActionName: 'participants',

  createEditView: function (model) {
    var editView = new TaskEditView({
      model: new Task(model.attributes, {
        portletId: this.options.portletId,
      }),
      portletId: this.options.portletId,
      $portlet: this.options.$portlet,
    });
    editView.backingModel = model;
    return editView;
  },

  i18n: i18n,

  removeActivity: function () {
    $.proxy(removeActivityInternal, this, function () {
      this.closePopover();
    })();
  },

  templateHelpers2: {
    timeSpan: function (start, end, allDay) {
      var dateFormat = allDay ? 'll' : 'lll',
        startMoment = moment(start),
        startHuman = startMoment.format(dateFormat);
      if (end) {
        var endMoment = moment(end);
        if (
          startMoment.format('YYYY-MM-DD') === endMoment.format('YYYY-MM-DD')
        ) {
          if (!allDay) {
            return startHuman + ' - ' + endMoment.format('LT');
          }
        } else if (startMoment.format('YYYY') === endMoment.format('YYYY')) {
          return (
            startHuman + ' - ' + stripYear(end, endMoment.format(dateFormat))
          );
        } else {
          return startHuman + ' - ' + endMoment.format(dateFormat);
        }
      }
      return startHuman;
    },
  },
});

// TASK VIEW ---------------------------------------------------------------
var TaskView = Backbone.View.extend({
  template: function () {
    return getTemplate(this.options.$portlet, 'task');
  },

  tagName: 'li',

  className: 'sv-task',

  events: {
    'change [data-fn-done]': 'toggleDone',
    'click [data-fn-details]': 'showDetails',
    'click [data-fn-edit]': 'showEdit',
  },

  initialize: function (options) {
    this.options = options;

    this.bindModelEvents();

    var template = this.template();
    this.$el.html(template(this.model.toJSON()));

    this.ui = {
      taskLink: this.$el.find('.sv-task-link'),
    };
  },

  render: function () {
    if (this.model.get('editable')) {
      // set data to be able to DnD tasks to the calendar (see calendar-portlet.js)
      this.$el.data('eventObject', this.model.attributes);

      this.$el.draggable({
        zIndex: 999,
        revert: true, // will cause the event to go back to its
        revertDuration: 0, //  original position after the drag
        helper: 'clone',
        distance: 20,
      });

      this.renderHover(this.$el);
    }
    this.toggleVisible();
    return this;
  },

  bindModelEvents: function () {
    this.listenTo(this.model, 'filter', this.toggleVisible, this);
    this.listenTo(this.model, 'change', this.render, this);
    this.listenTo(this.model, 'change', this.markOverdue, this);
  },

  toggleDone: function () {
    this.model.set('isDone', !this.model.get('isDone'));

    this.model.save(null, {
      success: $.proxy(function (model) {
        this.markOverdue();
        // propagate changes to calendar event (see calendar-portlet.js)
        events.trigger(events.types.activityUpdated, model.attributes);
      }, this),
    });
  },

  showDetails: function (e) {
    var $target = $(e.target);

    if ($target.prop('popover-open')) {
      $target.removeProp('popover-open');

      return;
    }

    $target.prop('popover-open', true);

    new TaskDetailView({
      model: this.model,
      portletId: this.options.portletId,
      $portlet: this.options.$portlet,
      $target: $target,
      popoverContainer: $target.closest('.sv-fn-tasks'),
      i18n: i18n,
    }).render();
    return false;
  },

  showEdit: function (e) {
    e.stopPropagation(); // avoid opening the detail popup as well
    if (this.model.get('editable')) {
      var editView = new TaskEditView({
        model: new Task(this.model.attributes, {
          portletId: this.options.portletId,
        }),
        portletId: this.options.portletId,
        $portlet: this.options.$portlet,
      });
      editView.backingModel = this.model;
      editView.render();

      if (clientUtil.isTouchDevice) {
        $(window).scrollTop(0);
      }
    } else {
      this.showDetails(e);
    }
    return false;
  },

  markOverdue: function () {
    var date = this.model.get('end');
    if (!date) {
      date = this.model.get('start');
    }
    if (date) {
      var dateMoment = moment(date),
        now = moment();

      if (this.model.get('allDay')) {
        dateMoment.startOf('day').add(1, 'days').subtract(1, 'seconds');
      }

      if (!this.model.get('isDone') && dateMoment.isBefore(now)) {
        this.ui.taskLink.addClass('sv-task-overdue');
      } else {
        this.ui.taskLink.removeClass('sv-task-overdue');
      }
    } else {
      this.ui.taskLink.removeClass('sv-task-overdue');
    }
  },

  renderHover: function ($el) {
    $el.find('.sv-task-title').addClass('sv-task-title-owner');
  },

  toggleVisible: function () {
    var isDone = this.model.get('isDone'),
      show =
        filter === 'all' ||
        (isDone && filter === 'done') ||
        (!isDone && filter === 'notDone');
    this.$el.toggleClass('env-d--none', !show);
  },
});

// TASK COLLECTION VIEW ----------------------------------------------------
var TaskListView = Backbone.View.extend({
  events: {
    'click [data-fn-filter]': 'setFilter',
    'keyup [data-fn-create-input]': 'handleInputFieldKeyPress',
    'click [data-fn-create-button]': 'createOnClick',
  },

  initialize: function (options) {
    this.options = options;
    this.editable = this.$el.find('.sv-fn-tasks').data('editable');

    this.bindCollectionEvents();
    this.bindGlobalEvents();

    this.ui = {
      list: this.$el.find('[data-sv-fn-task-list]'),
      input: this.$el.find('[data-fn-create-input]'),
      createButton: this.$el.find('[data-fn-create-button]'),
      percentDone: this.$el.find('.sv-percentDone'),
      filter: this.$el.find('[data-toggle="dropdown"]').parent(),
      createContainer: this.$el.find('[data-create-assignment-container]'),
      errorMessage: this.$el.find('[data-fn-create-error-message]'),
      tasksDone: this.$el.find('.sv-fn-tasks-done'),
    };
  },

  render: function () {
    this.clearList();

    if (this.collection.length > 0) {
      this.collection.each(function (item) {
        this.prependOne(item);
      }, this);

      events.trigger(events.types.updateRelativeDates, this.ui.list);
      this.updateTotalCount(this.collection);
    }
    return this;
  },

  activityUpdated: function (attributes) {
    var task = this.collection.get(attributes.id);
    if (task) {
      if (!attributes.isTask) {
        this.collection.remove(task);
      } else {
        task.set(attributes);
        this.doSortAndRender();
      }
    }
  },

  activityCreated: function (attributes) {
    var task = this.collection.get(attributes.id);
    if (attributes.isTask && !task) {
      this.collection.add(
        new Task(attributes, {
          portletId: this.options.portletId,
        })
      );
    }
  },

  activityDeleted: function (attributes) {
    var task = this.collection.get(attributes.id);
    if (task) {
      this.collection.remove(task);
    }
  },

  bindGlobalEvents: function () {
    events.on(
      events.types.activityUpdated,
      $.proxy(this.triggeredUpdateCount, this)
    );

    // an event was updated in the calendar (see calendar-portlet.js) - update this model as well
    events.on(
      events.types.activityUpdated,
      $.proxy(this.activityUpdated, this)
    );
    events.on(
      events.types.activityCreated,
      $.proxy(this.activityCreated, this)
    );
    events.on(
      events.types.activityDeleted,
      $.proxy(this.activityDeleted, this)
    );

    // unmark the new tasks, triggered by timed remote call (see groupPage.js)
    events.on(
      events.types.groupPageViewed,
      $.proxy(function () {
        this.collection.each(function (model) {
          model.set('markAsUnread', false);
        }, this);
      }),
      this
    );
  },

  bindCollectionEvents: function () {
    this.listenTo(this.collection, 'add', this.appendOne, this);
    this.listenTo(this.collection, 'remove', this.render, this);
    this.listenTo(this.collection, 'destroy', this.render, this);
    this.listenTo(this.collection, 'change', this.doSortAndRender, this);
  },

  doSortAndRender: function () {
    this.collection.sort();
    this.render();
  },

  appendOne: function (task) {
    var view = this.createAndRenderTaskView(task);
    this.ui.list.append(view.el);
    this.updateTotalCount(this.collection);
  },

  prependOne: function (task) {
    var view = this.createAndRenderTaskView(task);
    this.ui.list.prepend(view.el);
    this.updateTotalCount(this.collection);
  },

  createAndRenderTaskView: function (model) {
    return new TaskView({
      model: model,
      portletId: this.options.portletId,
      $portlet: this.$el,
      editable: this.editable,
    }).render();
  },

  showErrorMessage: function () {
    // The screen reader will not see the toggle of the text as a change in
    // content and will therefore not be able to announce the error a second time.
    // Toggle aria-hidden is nessesary for making the screen reader to see
    // the difference in content.
    this.ui.errorMessage.attr('aria-hidden', 'true');
    this.ui.createContainer.addClass('error');
    setTimeout(() => {
      this.ui.errorMessage.removeAttr('aria-hidden').text(i18n('invalidValue'));
    }, 0);
  },

  removeErrorMessage: function () {
    this.ui.createContainer.removeClass('error');
    this.ui.errorMessage.text('');
  },

  handleInputFieldKeyPress: function (e) {
    var keyCode = keyUtil.getKeyCodeFromEvent(e);

    if (keyCode !== keyUtil.KEY.RETURN) {
      return;
    }

    e.preventDefault();
    this.createTaskIfNotEmpty();
  },

  createOnClick: function () {
    this.createTaskIfNotEmpty();
  },

  createTaskIfNotEmpty: function () {
    var value = this.ui.input.val(),
      title = value ? value.trim() : '';

    if (title.length === 0) {
      this.showErrorMessage();
      return false;
    }
    this.collection.create(
      {
        title: title,
        owner: '-', // just to satisfy the validation, will be set on the server
      },
      {
        wait: true,
        success: function (model) {
          events.trigger(events.types.activityCreated, model.attributes);
        },
      }
    );
    this.ui.input.val('');
    this.removeErrorMessage();

    return false;
  },

  triggeredUpdateCount: function () {
    this.updateTotalCount(this.collection);
  },

  updateTotalCount: function (collection) {
    var totalCount = collection.size(),
      finishedCount = 0;

    collection.forEach(function (task) {
      if (task.get('isDone')) {
        finishedCount++;
      }
    });
    var percent = 0;
    if (totalCount > 0) {
      percent = Math.round((finishedCount / totalCount) * 100);
    }

    this.ui.percentDone.text(percent + '%');
    this.ui.percentDone.data('fn-finishedCount', finishedCount);
    this.ui.percentDone.data('fn-totalCount', totalCount);
    this.ui.tasksDone.text(i18n('doneText', [finishedCount, totalCount]));
  },

  clearList: function () {
    this.ui.list.empty();
  },

  filterOne: function (task) {
    task.trigger('filter');
  },

  filterAll: function () {
    this.collection.each(function (element) {
      this.filterOne(element);
    }, this);
  },

  setFilter: function (e) {
    var $target = $(e.currentTarget),
      $menu = $target.closest('.dropdown-menu'),
      filterValue = $target.data('fn-action'),
      $currentlySelected = $menu.find('[data-fn-action=' + filter + ']');

    if (filter === filterValue) {
      return;
    }

    filter = filterValue;
    this.selectFilterValue($currentlySelected, false);
    this.selectFilterValue($target, true);

    this.filterAll();
    this.ui.filter.removeClass('open');
    return false;
  },

  selectFilterValue: function ($el, select) {
    $el.find('i').toggleClass('sv-empty-icon', !select);
    $el.find('i').toggleClass('halflings-icon ok', select);
  },
});

// PORTLET INITIALIZE ------------------------------------------------------
$('.sv-tasks-portlet').each(function () {
  var $this = $(this),
    portletId = objectUtil.getObjectId($this.attr('id')),
    jsNamespace = $this.find('.sv-fn-tasks').data('js-namespace'),
    tasks = new TaskList(sv[jsNamespace], {
      id: portletId,
      portletId: portletId,
    });

  new TaskListView({
    el: $this,
    collection: tasks,
    portletId: portletId,
  }).render();

  $this.on('mouseenter', '.sv-percentDone', function () {
    var $this = $(this),
      total = $this.data('fn-totalCount'),
      finished = $this.data('fn-finishedCount');
    $this.tooltip('destroy');
    $this.tooltip({
      html: true,
      title:
        '<div style="white-space:nowrap; font-size:0.688rem">' +
        i18n('doneText', [finished, total]) +
        '</div>',
    });
    $this.tooltip('show');
  });

  $this.on('mouseenter', '.sv-task-clock', function () {
    var $this = $(this),
      timeTag = $this.children('time'),
      machineReadableTime = parseInt(timeTag.attr('dateTime'), 10),
      time = moment(machineReadableTime),
      format = timeTag.attr('data-fn-format');
    $this.tooltip('destroy');
    $this.tooltip({
      html: true,
      title:
        '<div style="white-space:nowrap">' + time.format(format) + '</div>',
    });
    $this.tooltip('show');
  });
});
